import React, { useState, useContext } from "react";
import { Modal, Button, ListGroup } from "react-bootstrap";
import { FaRegBell } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { useZafClient } from "../../utils/zafClient";
import Loader from "../Loader/Loader";
import { ApiConfig } from "../../Config/ApiConfig";
import { formatDistanceToNow } from "date-fns";
import { LuMailCheck } from "react-icons/lu";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiCheck, BiCheckDouble } from "react-icons/bi";
import { Link } from "react-router-dom";
import { TicketContext } from "../../Context/TicketContext";

const NotificationModal = () => {
  const client = useZafClient();

  const {
    notifications,
    setNotifications,
    isLoadingData,
    handleShowPerformanceModal,
    handleTabSelectPerformanceModal,
    setTicketID,
    setNotificationTicketQA,
  } = useContext(TicketContext);

  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("new");

  const [isDeletingOne, setIsDeletingOne] = useState(false);
  const [isDeletingAll, setIsDeletingAll] = useState(false);
  const [isReadingOne, setIsReadingOne] = useState(false);
  const [isReadingAll, setIsReadingAll] = useState(false);

  // State for confirmation modals
  const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] =
    useState(false);
  const [showReadAllConfirmation, setShowReadAllConfirmation] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  function getTicketNumberFromUrl(url) {
    const match = url.match(/tickets\/(\d+)\.json/);
    if (match && match[1]) {
      return match[1];
    }
    return null;
  }

  const handleReadOne = async (notification, read = true) => {
    if (!client) return;

    const currentUser = await client?.get?.(["currentUser"]);
    const apiData = {
      user_id: currentUser?.currentUser?.email,
      notification_id: notification.notification_id,
    };

    try {
      setIsReadingOne(true);
      await axios.post(ApiConfig.notificationReadOne, apiData, {
        headers: { "Access-Control-Allow-Origin": "*" },
        withCredentials: true,
      });
      setNotifications((prevNotifications) =>
        prevNotifications.map((notif) => ({ ...notif, is_read: true }))
      );
      if (notification.type === "QA Comment" && read) {
        handleTabSelectPerformanceModal("TicketQA");
        setTicketID(getTicketNumberFromUrl(notification.ticket_url));
        setNotificationTicketQA(true);
        handleShowPerformanceModal();
      }
    } catch (error) {
      toast.error(
        error.response?.data?.detail ? (
          <div>
            <strong>#{error.response.data.detail.error_code}</strong>
            &nbsp;&nbsp;
            {error.response.data.detail.masked_error}
          </div>
        ) : (
          "Something went wrong. Please try again later."
        )
      );
    } finally {
      setIsReadingOne(false);
    }
  };
  const handleReadAll = async () => {
    if (!client) return;

    const currentUser = await client?.get?.(["currentUser"]);
    const apiData = {
      user_id: currentUser?.currentUser?.email,
    };

    try {
      setIsReadingAll(true);
      await axios.post(ApiConfig.notificationReadAll, apiData, {
        headers: { "Access-Control-Allow-Origin": "*" },
        withCredentials: true,
      });
      setNotifications((prevNotifications) =>
        prevNotifications.map((notif) => ({ ...notif, is_read: true }))
      );
    } catch (error) {
      toast.error(
        error.response?.data?.detail ? (
          <div>
            <strong>#{error.response.data.detail.error_code}</strong>
            &nbsp;&nbsp;
            {error.response.data.detail.masked_error}
          </div>
        ) : (
          "Something went wrong. Please try again later."
        )
      );
    } finally {
      setIsReadingAll(false);
      setShowReadAllConfirmation(false);
    }
  };
  const handleDeleteOne = async (notificationId) => {
    if (!client) return;

    const currentUser = await client?.get?.(["currentUser"]);
    const apiData = {
      user_id: currentUser?.currentUser?.email,
      notification_id: notificationId,
    };

    try {
      setIsDeletingOne(true);
      await axios.post(ApiConfig.notificationDeleteOne, apiData, {
        headers: { "Access-Control-Allow-Origin": "*" },
        withCredentials: true,
      });
      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notif) => notif.notification_id !== notificationId
        )
      );
    } catch (error) {
      toast.error(
        error.response?.data?.detail ? (
          <div>
            <strong>#{error.response.data.detail.error_code}</strong>
            &nbsp;&nbsp;
            {error.response.data.detail.masked_error}
          </div>
        ) : (
          "Something went wrong. Please try again later."
        )
      );
    } finally {
      setIsDeletingOne(false);
    }
  };
  const handleDeleteAll = async () => {
    if (!client) return;

    const currentUser = await client?.get?.(["currentUser"]);
    const apiData = {
      user_id: currentUser?.currentUser?.email,
    };

    try {
      setIsDeletingAll(true);
      await axios.post(ApiConfig.notificationDeleteAll, apiData, {
        headers: { "Access-Control-Allow-Origin": "*" },
        withCredentials: true,
      });
      setNotifications([]);
    } catch (error) {
      toast.error(
        error.response?.data?.detail ? (
          <div>
            <strong>#{error.response.data.detail.error_code}</strong>
            &nbsp;&nbsp;
            {error.response.data.detail.masked_error}
          </div>
        ) : (
          "Something went wrong. Please try again later."
        )
      );
    } finally {
      setIsDeletingAll(false);
      setShowDeleteAllConfirmation(false);
    }
  };
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  const filteredNotifications = () => {
    switch (activeTab) {
      case "new":
        return notifications.filter((notif) => !notif.is_read); // Unread notifications
      case "read":
        return notifications.filter(
          (notif) => notif.is_read && new Date(notif.created_at) >= oneWeekAgo
        ); // Read notifications from the last week
      case "archive":
        return notifications.filter(
          (notif) => notif.is_read && new Date(notif.created_at) < oneWeekAgo
        ); // Archived (read and older than a week)
      default:
        return notifications;
    }
  };

  const renderNotifications = (notifications) => {
    if (isLoadingData) {
      return <Loader />;
    }

    if (notifications.length === 0) {
      return <h5 className="text-muted text-center my-3">No Notifications</h5>;
    }

    return (
      <ListGroup
        style={{ maxHeight: "400px", overflowY: "auto" }}
        variant="flush"
        className="notification-list"
      >
        {notifications.map((notification) => (
          <ListGroup.Item
            key={notification.notification_id}
            action
            className={`py-1 px-2 ${!notification.is_read ? "bg-light" : ""}`}
            style={{
              borderBottom: "1px solid #e9ecef",
              cursor: "pointer",
            }}
            onClick={() => handleReadOne(notification)}
            disabled={isReadingOne}
          >
            <div class="d-flex w-100 justify-content-between">
              <h6 class="mb-1"> {notification.title}</h6>
              <small style={{ fontSize: "10px" }} className="fw-semibold">
                {formatDistanceToNow(new Date(notification.created_at), {
                  addSuffix: true,
                })}
              </small>
            </div>
            <p
              className="mb-1 fw-semibold"
              style={{
                fontSize: "12px",
                whiteSpace: "nowrap", // Prevent text wrapping
                overflow: "hidden", // Hide the overflow
                textOverflow: "ellipsis", // Show '...' for overflowing text
                width: "100%", // Ensure it takes up the full width available
                maxWidth: "350px",
                transition: "all 0.3s ease", // Smooth transition when hovering
              }}
              onMouseEnter={(e) => {
                e.target.style.whiteSpace = "normal"; // Allow text to wrap on hover
                e.target.style.overflow = "visible"; // Show full text on hover
              }}
              onMouseLeave={(e) => {
                e.target.style.whiteSpace = "nowrap"; // Truncate text when hover ends
                e.target.style.overflow = "hidden"; // Hide overflow text when hover ends
              }}
            >
              {notification.message}
            </p>
            <div className="d-flex justify-content-end m-0 p-0">
              {notification.is_read ? (
                <Button
                  variant="link"
                  style={{
                    textDecoration: "none",
                  }}
                  className="m-0 p-0"
                >
                  <BiCheckDouble
                    className="text-success"
                    style={{ marginRight: "8px" }}
                  />
                </Button>
              ) : (
                <Button
                  variant="link"
                  style={{
                    textDecoration: "none",
                  }}
                  className="m-0 p-0 grow-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReadOne(notification, false);
                  }}
                >
                  <BiCheck
                    className="text-secondary"
                    style={{ marginRight: "8px" }}
                  />
                </Button>
              )}
              <Button
                variant="link"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteOne(notification.notification_id);
                }}
                style={{
                  color: "grey",
                  textDecoration: "none",
                }}
                disabled={isDeletingOne}
                className="m-0 p-0 grow-icon"
              >
                <RiDeleteBin6Line className="m-0 p-0" />
              </Button>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  };

  return (
    <>
      <Button
        variant="link"
        onClick={handleShow}
        className="p-1 ms-2 position-relative grow-icon"
        style={{
          textDecoration: "none",
          color: "black",
        }}
      >
        <FaRegBell style={{ fontSize: "1.25rem" }} />
        <span
          className="position-absolute top-0 start-75 translate-middle badge rounded-pill bg-danger"
          style={{ fontSize: "0.5rem" }}
        >
          {notifications.filter((n) => !n.is_read).length}
        </span>
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="border border-0 py-2 px-3">
          <Modal.Title style={{ fontSize: "16px" }}>
            Your Notifications
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="px-4 mt-2">
            <ul className="nav nav-underline">
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    activeTab === "new" ? "active" : ""
                  } p-0 px-2`}
                  onClick={() => handleTabClick("new")}
                >
                  New&nbsp;
                  <span className="badge rounded-pill bg-info-subtle px-2 text-dark">
                    {notifications.filter((notif) => !notif.is_read).length}
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    activeTab === "read" ? "active" : ""
                  } p-0 px-2`}
                  onClick={() => handleTabClick("read")}
                >
                  Read&nbsp;
                  <span className="badge rounded-pill bg-info-subtle px-2 text-dark">
                    {
                      notifications.filter(
                        (notif) =>
                          notif.is_read &&
                          new Date(notif.created_at) >= oneWeekAgo
                      ).length
                    }
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    activeTab === "archive" ? "active" : ""
                  } p-0 px-2`}
                  onClick={() => handleTabClick("archive")}
                >
                  Archive
                </Link>
              </li>
            </ul>
          </div>

          <hr className="m-0 p-0" />
          <div className="py-2">
            {renderNotifications(filteredNotifications())}
          </div>
        </Modal.Body>
        <Modal.Footer className="p-1">
          <div className="d-flex justify-content-around">
            <Button
              variant="link"
              size="sm"
              onClick={() => setShowDeleteAllConfirmation(true)}
              disabled={notifications.length <= 0}
              style={{
                textDecoration: "none",
                color: "grey",
                fontSize: "1.25rem",
              }}
              className="p-0 grow-icon"
            >
              <RiDeleteBin6Line />
            </Button>
            <Button
              variant="link"
              size="sm"
              onClick={() => setShowReadAllConfirmation(true)}
              disabled={notifications.length <= 0}
              style={{ textDecoration: "none", fontSize: "1.25rem" }}
              className="mx-3 p-0 grow-icon"
            >
              <LuMailCheck />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Delete All Confirmation Modal */}
      <Modal
        show={showDeleteAllConfirmation}
        onHide={() => setShowDeleteAllConfirmation(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete all notifications?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteAllConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteAll}
            disabled={isDeletingAll}
          >
            Delete All
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Read All Confirmation Modal */}
      <Modal
        show={showReadAllConfirmation}
        onHide={() => setShowReadAllConfirmation(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Mark All as Read</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to mark all notifications as read?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowReadAllConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleReadAll}
            disabled={isReadingAll}
          >
            Mark All as Read
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotificationModal;
